import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PostTopmatter from "../components/post_topmatter"
const _ = require("lodash")

export default function Post ({ data, pageContext }) {
	console.log(data)
	console.log(pageContext)
	const post = data.markdownRemark
	const frontmatter = post.frontmatter
	return (
		<Layout 
			width={frontmatter.postWidth}>
			<div className="container">
				<div className="row">
					<div className="col d-flex justify-content-center">
						<div
							className="post--inner"
							style={{
								borderColor: frontmatter.borderColor || data.site.siteMetadata.defaultAccentColor,
								borderWidth: frontmatter.borderWidth || "3px",
								width: frontmatter.postWidth || "720px"
							}}
						>
							<PostTopmatter
								date={frontmatter.publishDate}
								underlineColor={
									frontmatter.borderColor || "rgba(247,227,227, 0.5)"
								}
								title={frontmatter.title}
								showTitle={
									frontmatter.showTitle === null ? true : frontmatter.showTitle
								}
								isDraft={frontmatter.isDraft}
							/>
							<div dangerouslySetInnerHTML={{ __html: post.html }} />

							<div className="post--bottommatter" style={{borderTop: "1px solid", borderColor: frontmatter.borderColor || data.site.siteMetadata.defaultAccentColor}}>
								{frontmatter.tags.map((tag) => {																		
	    				        	return <Link to={`/tags/${_.kebabCase(tag)}/`}
	    				        				 className="post--bottommatter--tag">
    				        				 	{tag}
    				        				</Link>
								})}
							</div>
						</div>					
					</div>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query($slug: String!) {

	    site {
    		siteMetadata {
      			defaultAccentColor
    		}
		},

		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				isDraft
				showTitle
				title
				publishDate
				postWidth
				borderColor
				borderWidth
				tags
			}
		}
	}
`
